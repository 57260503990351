@import 'styles/index.sass';

.btn {
	display: flex;
	justify-content: center;
	align-items: center;

	&_primary {
		color: white;
		background: $c-primary;

		&:hover {
			transition: 0.3s;
			background: $c-pink; } }

	&_secondary {
		color: #4b4a49;
		background: #d6dddf; }

	&_third {
		color: $c-primary;

		&:hover {
			transition: 0.3s;
			color: $c-pink; } }

	&_size_s {
		height: 36px;
		padding: 8px 16px;
		font-size: 18px;
		line-height: 18px;
		font-weight: bold;
		border-radius: 4px; }

	&_size_m {
		height: 40px;
		padding: 8px 25px;
		font-size: 18px;
		line-height: 24px;
		border-radius: 8px; }

	&_size_l {
		height: 56px;
		padding: 4px 25px;
		font-size: 20px;
		line-height: 48px;
		border-radius: 8px; }

	&_stretch {
		width: 100%; }

	&_disabled {
		background: #eaebee;
		color: #b8b8b8;

		&:hover {
			background: #eaebee; } } }

