@import 'mixins.sass';

.plyr--video {
	height: 500px;

	@include mobile {
		height: 250px; } }

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: $c-pink !important; }

.plyr__control--overlaid {
	background: $c-pink !important; }

.plyr--full-ui input[type=range] {
	color: $c-pink !important; }

.plyr__control.plyr__tab-focus,
.plyr__control:hover,
.plyr__control[aria-expanded=true] {
	background: $c-pink !important; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
	background: $c-pink !important; }
