@import 'styles/index.sass';

.header {
	position: fixed;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 16px calc(30px + 100% - 100vw) 16px 30px;
	border-bottom: 1px solid $c-line;
	background: white;
	z-index: 2;

	@include mobile {
		justify-content: space-between;
		padding: 8px 16px; }

	.flex {
		display: flex;
		align-items: center; }

	.logo {
		width: 64px;

		@include mobile {
			width: 48px;
			margin-left: 48px; } }

	.avatar {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		object-fit: cover; }

	.notification {
		&__button {
			position: relative;
			margin-right: 24px; }

		&__count {
			position: absolute;
			top: -10px;
			right: -10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 22px;
			height: 22px;
			padding-top: 2px;
			border-radius: 22px;
			color: white;
			background: $c-primary;
			font-size: 10px;
			font-weight: bold; } } }

.navigation {
	font-weight: 700;

	@include laptop {
		margin-left: 66px; }

	@include mobile {
		text-align: center; }

	ul {
		@include laptop {
			display: flex;
			align-items: center; } }

	li {
		@include laptop {
			margin-right: 30px; }

		@include mobile {
			margin-bottom: 30px; } }

	a {
		&:hover {
			transition: 0.3s;
			color: $c-pink; } } }

ol, ul, li {
	padding: 0;
	margin: 0;
	border: 0;
	list-style: none; }

.menu {
	position: relative;

	&__avatar {
		width: 32px;
		height: 32px;

		img {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			object-fit: cover; }

		.plug {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #f9f7f6; } }

	button {
		display: flex;
		align-items: center;

		span {
			margin: 0 16px; } }

	.popup {
		position: absolute;
		top: 40px;
		right: 0;
		min-width: 150px;
		padding: 16px;
		background: white;
		box-shadow: 0px 1px 3px rgba(black, 0.25);
		border-radius: 16px;

		ul {
			display: block; }

		li, a, button {
			font-weight: 400;
			color: black;

			&:hover {
				color: $c-pink; }

			&:not(:last-child) {
				margin-bottom: 8px; } } } }

