@import 'styles/index.sass';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;

	.content {
		flex: 1 0 auto;
		background: url('/assets/images/bg_1.png') no-repeat top 25px left -470px,
		url('/assets/images/bg_2.png') no-repeat top 100px right -345px,
		url('/assets/images/bg_3.png') no-repeat bottom -440px left 0 {}

		@include mobile {
			background: url('/assets/images/bg_1.png') no-repeat top -165px left -530px,
			url('/assets/images/bg_2.png') no-repeat top -260px right -650px,
			url('/assets/images/bg_3.png') no-repeat bottom -545px left -240px {} } }

	.footer {
		flex: 0 0 auto;

		@include mobile {
			flex-direction: column;
			align-items: flex-start; } } }

