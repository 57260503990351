@import 'styles/index.sass';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;

	.content {
		position: relative;
		flex: 1 0 auto;
		margin-top: 97px;
		//margin-right: calc(100% - 100vw)

		@include mobile {
			margin-top: 65px; } }

	.footer {
		flex: 0 0 auto;

		@include mobile {
			flex-direction: column;
			align-items: flex-start; } } }

