@import 'styles/index.sass';

.wrapper {
	margin: 80px 0 80px 160px;

	@include mobile {
		margin: 32px 0 74px;
		padding: 0 24px;
		text-align: center; }

	.logo {
		width: 120px;
		height: 120px;
		object-fit: contain;
		margin-bottom: 32px;

		@include mobile {
			width: 48px;
			margin-bottom: 16px; } }

	h1 {
		max-width: 400px; }

	.form {
		margin-top: 24px;

		@include laptop {
			width: 320px; }

		@include mobile {
			margin-top: 40px; }

		&__field {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 24px; } }

		&__controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 24px; }

		&__error {
			margin-bottom: 16px;
			color: $c-pink; }

		.link {
			display: flex;
			justify-content: center;
			margin-top: 24px;
			@include link; } } }

