@import 'styles/index.sass';

.wrapper {
	width: 320px;
	margin-top: 80px;
	margin-left: 160px;

	@include mobile {
		width: 312px;
		margin: 32px auto 74px;
		text-align: center; }

	.logo {
		width: 120px;
		height: 120px;
		object-fit: contain;
		margin-bottom: 32px;

		@include mobile {
			width: 48px;
			margin-bottom: 16px; } }

	.form {
		margin-top: 24px;

		@include mobile {
			margin-top: 40px; }

		&__field {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 24px; } }

		&__controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 24px; }

		&__error {
			margin-bottom: 16px;
			color: $c-pink; } }

	.link {
		display: flex;
		justify-content: center;
		margin-top: 24px;
		@include link; }

	.success_message {
		margin-top: 24px;
		font-size: 20px;
		line-height: 29px;
		font-weight: 400;
		color: $c-gray; } }

