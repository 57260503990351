@import 'styles/index.sass';

.select {
	width: fit-content;
	position: relative;

	&__control {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 8px;
		background: white;
		box-shadow: 0px 1px 3px rgba(black, 0.25);
		border-radius: 10px;

		&__icon {
			margin-left: 6px; } } }

.popup {
	position: absolute;
	top: 50px;
	right: 0;
	min-width: 185px;
	padding: 16px;
	background: white;
	box-shadow: 0px 1px 3px rgba(black, 0.25);
	border-radius: 16px;
	z-index: 1;

	ul {
		display: block; }

	li, a, button {
		font-weight: 400;
		color: black;
		margin: 0;

		&:hover {
			color: $c-pink; }

		&:not(:last-child) {
			margin-bottom: 8px; } } }
